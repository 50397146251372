
.spinner{
    animation: 3s rotate linear infinite;
    transform-origin: 50% 50%;
}


@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}