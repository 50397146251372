.dashboard {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-template-rows: 60px 1fr;
    grid-template-areas: 
        "menu header"
        "menu main";
    background: #07151f;
    /* box-shadow: 100px rgba(255, 255, 255, 0.76); */
    caret-color: white;
    color: #ffffff;
    transition: all 1s ease;
}

.header {
    grid-area: header;
    height: 60px;
    width: auto;
    transition: height 1s ease;
}

.main {
    grid-area: main;
    /* border-top: solid rgba(158, 154, 154, 0.185) 2px; */
    height: 95%;
    overflow-y: auto;
    transition: all 1s ease;
}

.menu {
    grid-area: menu;
    background-color: #07151f;
    transition: width 1s ease, opacity 1s ease;
    overflow: hidden; /* Asegura que el contenido no se desborde */
}

.menu.closed {
    width: 60px;
}

.menu.open {
    width: 180px;
}

.main-expanded {
    grid-template-columns: 60px 1fr;
}

.main-open {
    grid-template-columns: 180px 1fr;
}

.menu .ul-nav {
    width: 180px;
    padding-bottom: 60px;
    background-color: #07151f;
    z-index: 99;
    overflow-y: auto;
    overflow-x: hidden;
    will-change: transform;
    backface-visibility: hidden;
    padding: 5px;
    transition: width 1s ease, opacity 1s ease;
}

.menu.closed .ul-nav {
    width: 60px;
    opacity: 1;
}

.ul-nav-li {
    display: block;
    user-select: none;
    list-style: none;
    font-size: 0.9rem;
}

.li-nav-div {
    padding: 0 0.5rem; /* Ajustar el relleno para que los íconos se vean bien */
    line-height: inherit;
    cursor: pointer;
    transition: all 1s ease;
    height: 36px;
}

.li-nav-div i {
    width: 1.5rem;
    font-size: 1.5rem;
    display: inline-block;
    text-align: center;
}

.li-nav-div {
    background: #07151f;
    height: 45px;
    color: #ffffff;
}

.li-nav-div-submenu {
    background: #07151f;
    color: #ffffff;
    padding: 0 10px !important;
    line-height: inherit;
    cursor: pointer;
    transition: all 1s ease;
    height: 40px;
}

/* .fondo-negro {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0);
    opacity: 0.3;
} */

.nav-link {
    padding: 0 0 0 2px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.nav-link.active {
    border-radius: 10px;
    background-color: #0c283b;
    color: #ffffff;
}

.li-a-submenu.active {
    border: 1px solid #fff;
    padding: 10px;
    box-shadow: 0 0 2px #fff, 0 0 2px #fff inset;
    color: #ffffff;
    border-radius: 10px;
}

.nav-link:hover {
    box-shadow: 0 0 2px #fff, 0 0 2px #fff inset;
    background-color: #122d3f;
    color: #ffffff;
    border-radius: 10px;
}

.nav-link.active:hover {
    color: rgb(255, 255, 255);
    background-color: #1e3849;
}


/* TABLE MONITOREO */



/* TABLE RESPONSIVE Instructores */
    /* .contenedor-tabla {
        height: calc(90% - 48px - 28px);
        overflow: auto;
    } */

/* @media screen and (max-width: 700px) { */
    .tableResponsiveMonitoreo {
        /* border: 2px solid red; */
        /* border-radius: 20px; */
        /* border: 1px solid #ccc; */
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;
        /* table-layout: fixed; */
        color:#ffffff
    }


    .tableResponsiveMonitoreo tbody tr:hover {
        background: #007c95!important;
        cursor: pointer;
        color: white;
        
    }


    .tableResponsiveMonitoreo caption {
        font-size: 1.5em;
        
    }

    .tableResponsiveMonitoreo th,
    .tableResponsiveMonitoreo td {
        /* padding-top: ; */
        /* text-align: center; */
        /* height: 20px; */
        /* border: 5px solid red; */
        /* background: #3a79a179!important; */

    }
    
    .tableResponsiveMonitoreo thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        
    }
    .tableResponsiveMonitoreo tbody tr:nth-child(odd) {
        background-color: #063250;
    }

    .tableResponsiveMonitoreo tbody tr:nth-child(even) {
        background-color: #04446F;
    }

    .tableResponsiveMonitoreo tr { /*CADA REGISTRO*/
        margin-bottom: 0px;
        border: 1px solid #184662;
        height: 220px;
        background: #202938;
        display: block;
    }

    .tableMonitoreoOpciones{
        text-align: center;
        border: 5 solid white;
        background: #184662;

        /* position:flex; */
    }

    .tableResponsiveMonitoreo td {
        display: block;
        font-size: 1em;
        width: 100%;
        text-align: right;
        padding-right: 5px;
        
    }

    .tableNumMonitoreo{
        width: 20px !important;
        font-size: 1.2em !important;
        font-weight:  bolder;
        text-align: left;
        /* border:2px solid red; */
        /* text-decoration: underline; */
    }
    .tableFotoMonitoreo{
        position: absolute;
        margin-top: 10px;
        margin-left: 10px;

    }
    
    
/* } */


/* ESTILO DEL SCROLLBAR PRINCIPAL */
.containerScroll::-webkit-scrollbar {
    width: 10px;
    height: 8px;
}

.containerScroll::-webkit-scrollbar-thumb {
    background: #2e5770;
}

.containerScroll::-webkit-scrollbar-thumb:hover {
    background: #2e5770;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.containerScroll::-webkit-scrollbar-thumb:active {
    background-color: #101b27;
}

.containerScroll::-webkit-scrollbar-track {
    background: #263e4c;
}

.containerScroll::-webkit-scrollbar-track:hover,
.containerScroll::-webkit-scrollbar-track:active {
    background: #113b67;
}

/* PARA DISPOSITIVOS MOBILES */
@media screen and (max-width: 639px) { 
    .containerScroll::-webkit-scrollbar {
        width: 1px;
        height: 0px;
    }
    
    .containerScroll::-webkit-scrollbar-thumb {
        background: #113b67;
    }
    
    .containerScroll::-webkit-scrollbar-thumb:hover {
        background: #113b67;
    }
    
    .containerScroll::-webkit-scrollbar-thumb:active {
        background-color: #113b67;
    }
    
    .containerScroll::-webkit-scrollbar-track {
        background: #101b27;
    }
    
    .containerScroll::-webkit-scrollbar-track:hover,
    .containerScroll::-webkit-scrollbar-track:active {
        background: #101b27;
    }
}

/* FIN SCROLLBAR*/




/* FloatingButton.css */
.botonNuevo {

    transition: all 1s ease;
}
.botonNuevo:hover {
    transform: scale(1.5) rotate(360deg);
    /* width: 100px; */
}





    .fc-daygrid-day {
        height: auto; /* O prueba con valores específicos para ajustar */
        background-color: white !important;
        cursor: pointer; /* Cambia el cursor a puntero */

    }
    
    .fc-daygrid {
        overflow: hidden;
        background-color: #fff; /* Cambia el color de fondo de la cabecera de columna */
        border-radius: 20px !important; /* Ajusta este valor según tus preferencias */

    }

    .fc-event-title {
        color: #112940; 
                border:2px solid red;

    }
    
    .fc-col-header-cell {
        background-color: #4A5568 !important; /* Un tono de gris oscuro de Tailwind (gray-600) */
        color: #ffffff !important; /* Texto blanco para contraste */        height: 20px; /* Altura fija para las   celdas del encabezado */
        align-items: center; /* Alinea el contenido verticalmente al centro */
    }

    .fc-daygrid-day-top {
        justify-content: center !important; /* Centra el contenido horizontalmente */
        padding-top: 1px !important; /* Ajusta el padding superior para posicionar el número */

    }

    
    .fc-day-today {
        background-color: #f3f4f6 !important; /* Gris clarito, ajusta según tu paleta de colores */
        font-weight: bold !important; /* Texto en negrita */

    }

    .fc-daygrid-day:hover {
        background-color: #517e96 !important; /* Azul claro, ajusta según tu paleta de colores */
        color: #fff;
    }

    /* Aplica estilos a los encabezados de los días de la semana */
    .fc-col-header-cell-cushion {
        text-transform: uppercase !important; /* Transforma el texto a mayúsculas */
    }

    /* Redondea los bordes del contenedor principal del calendario */
    .fc .fc-toolbar {
        border-radius: 8px; /* Ajusta este valor según tus preferencias */
        overflow: hidden; /* Asegura que el contenido interno también se ajuste al borde redondeado */
    }
    
    /* Establece el color de fondo y el color de texto de los botones */
    /* Establece el color de fondo y el color de texto de los botones */
    .fc .fc-button {
        background-color: #112940 !important; /* Color de fondo */
        color: white !important; /* Color del texto */
        border: none !important; /* Elimina el borde de los botones */
    }
    

    
    /* Redondea los bordes de cada día en la vista del calendario */
    .fc .fc-daygrid-day {
        /* border-radius: 4px; */
    }
    /* Aplica un borde redondeado al contenedor principal del calendario */
    .fc {
        border-radius: 8px !important; /* Ajusta este valor según tus preferencias */
        /* overflow: hidden;  */
    }
    
    
    .fc-daygrid-event {
        max-height: 20px; /* Limita el alto de los eventos individuales */
        overflow: hidden; /* Oculta contenido que excede el alto */
    }
    
    .fc-daygrid-day-events {
        overflow-y: auto; /* Habilita desplazamiento vertical para el contenedor de eventos */
        max-height: 50px; /* Altura máxima antes de que el desplazamiento sea necesario */
    }
    




    /* CARDS DE DATOS USUARIOS */

    
    .clearfloat {
        content: '';
        display: block;
        clear: both;
    }
    .cards {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    .cards li {
        display: inline-block;
        margin: 10px;
        padding: 20px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 25px;
        box-shadow: 0 0 15px rgb(0, 0, 0);
        font-size: 0.9em;
        transition: all 0.5s ease-in-out;
        background-color: rgb(28, 45, 80);
        text-align: center;

        position: relative; /* Añadido para posicionar correctamente el efecto de cover */
        overflow: hidden; /* Para asegurarse de que la imagen no sobresalga del contenedor */
    }
    .cards li:after,
    .cards li:before {
        content: '';
        display: block;
        clear: both;
    }
    .cards li img {
        display: inline-block;
        /* width: 100%; */
        /* height: auto; */
        max-width: 160px;
        height: 180px;
        /* border:2px solid red; */
        /* border-radius: 50%; */
        box-shadow: 0 0 2px rgba(83, 83, 83, 0.5);
        transition: all 0.5s ease-in-out;
    }
    
    .cards li .details {
        float: left;
        text-align: left;
        min-width: 250px;
    }
    .cards li .details > span,
    .cards li .details > a {
        display: block;
        padding: 0 15px 0 35px;
        margin-bottom: 12px;
        text-decoration: none;
        position: relative;
    }

    .cards li:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.03);
    }
    .cards li:hover img {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
        transition: all 0.4s ease-in-out;
    }
    
    @media (max-width: 550px) {
        .cards li {
            display: block;
            width: auto;
            margin: 30px auto;
        }
        .cards li img,
        .cards li .details {
            float: none;
        }
        .cards li img {
            margin: 0 auto;
        }
        .cards li .details {
            margin-top: 20px;
            text-align: center;
            min-width: 0;
        }
       
    }
    
    /* ESTILOS CAMPO BUSCADOR */
    .search-input-container {
        max-width: 400px;  /* Ajusta este valor según sea necesario */
        width: 100%;
    }
    
    .text-white input {
        color: white !important;
        caret-color: white !important;
    }
    
    .text-white .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: white !important;
    }
    
    .text-white .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: white !important;
    }
    
    .text-white .MuiInputLabel-root {
        color: white !important;
    }
    
    .text-white .MuiButton-root {
        color: white !important;
    }

  
    
/* customCalendarStyles.css */

  
  .fc {
        border-radius: 10px !important; /* Redondeado más sutil para el contenedor principal */
    overflow: hidden;

  }
  
  .fc .fc-toolbar-title {
    font-family: 'Roboto', sans-serif;
    font-size: 2.0rem;
    font-weight: 500;
        /* background-color: #ffffff !important; */

    color: #ffffff !important; /* Color del título del calendario */
  }
  
  .fc .fc-button {
    
    background-color: #00945b !important;
    border: none;
    /* color: #0f385f !important; */
    font-family: 'Roboto', sans-serif;
    transition: background-color 0.3s ease;
  }
  
  .fc .fc-button:hover {
    background-color: #005c39 !important;
        border:2px solid red;

  }
  
  .fc .fc-button-primary:not(:disabled):active {
    background-color: #1565c0 !important;

  }
  
  .fc .fc-daygrid-day-number {
    color: #ffffff !important; /* Color del número del día */
    font-size: 1.3rem;
    font-weight: bold;

  }
  
  .fc .fc-event {
    background-color: #375688 !important;
    border: none;

    color: white !important;
    /* background-color: #ff4081; */
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.3);
  }
  
  .fc .fc-event:hover {
    background-color: #202d41 !important;

  }
  
  .fc .fc-daygrid-day {
    background-color: #f30505 !important;
    color: #0f385f !important;

  }
  
  .fc .fc-daygrid-day.fc-day-today {
    background-color: #122e43 !important;
    color: #0f385f !important;

  }
  
.fc .fc-day-today {
    background-color: #20395b !important; /* Color de fondo para el día actual */
}
  
  .fc .fc-daygrid-day:hover {
    color: #ffffff !important;

    background-color: #0d213d !important;
  }
  
  .fc .fc-daygrid-day.fc-day-past {
    color: #0f385f !important;
    background-color: #1c1e22 !important;
  }
    .fc .fc-daygrid-day.fc-day-past:hover {
    color: #0f385f !important;
    background-color: #1b1c1d !important;
  }
  
  .fc .fc-daygrid-day.fc-day-future {
    color: #0f385f !important;

    background-color: #01181d !important;
  }
    .fc .fc-daygrid-day.fc-day-future:hover {
    color: #81a4c4 !important;

    background-color: #02222a !important;
  }


/* Mejoras en loading.css o un archivo CSS separado */

.image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.image-container img {
    /* border-radius: 50%; */
    width: 200px;
    height: 200px;
    object-fit: cover;
    /* border: 3px solid #374151; */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
}

.image-container img:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.image-container .edit-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #161f30;
    color: #ffffff;
    border-radius: 50%;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 16px;
}

.image-container .edit-icon:hover {
    background-color: #374151;
    transform: scale(1.1);
}

.image-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 50%; */
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 24px;
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
}

.image-container:hover .overlay {
    opacity: 1;
}
